<template>
  <div class="release">
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="pd40 bg-ffffff bor-E6E6E6">
        <div>
          <div class="flex a-center">
            <div
              class="h60 w140 cup flex a-center j-center fs-18 fwb bg-d43030 col-ffffff"
              v-if="navIndex == 1"
            >
              转让出租发布
            </div>
            <div
              class="h60 w140 cup flex a-center j-center fs-18 fwb bg-d43030 col-ffffff"
              v-if="navIndex == 2"
            >
              招商出售发布
            </div>
            <div
              class="h60 w140 cup flex a-center j-center fs-18 fwb bg-d43030 col-ffffff"
              v-if="navIndex == 3"
            >
              二手货品发布
            </div>
            <div
              class="h60 w140 cup flex a-center j-center fs-18 fwb bg-d43030 col-ffffff"
              v-if="navIndex == 4"
            >
              找店选址发布
            </div>
          </div>
        </div>
        <div>
          <!-- 发布 -->
          <div class="pd40">
            <el-form ref="form" :model="form" label-width="11.25rem" label-position="right">
              <el-form-item label="选择类型：" v-if="navIndex == 1">
                <el-select
                  v-model="form.type"
                  placeholder="转让出租"
                  @change="changeType"
                  disabled
                >
                  <el-option label="转让" value="1"></el-option>
                  <el-option label="出租" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择类型：" v-if="navIndex == 2">
                <el-select
                  v-model="form.type"
                  placeholder="招商出售"
                  @change="changeType"
                  disabled
                >
                  <el-option label="招商" value="1"></el-option>
                  <el-option label="出售" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择类型：" v-if="navIndex == 3">
                <el-select
                  v-model="form.type"
                  placeholder="二手类型"
                  @change="changeType"
                  disabled
                >
                  <el-option label="转让" value="1"></el-option>
                  <el-option label="收购" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商圈名称：" v-if="navIndex == 2">
                <el-input v-model="form.cbd_name" placeholder="请输入商圈名称"></el-input>
              </el-form-item>
              <el-form-item label="商圈简介：" v-if="navIndex == 2">
                <Editor v-model="form.content" />
              </el-form-item>
              <el-form-item
                label="店铺封面："
                style="margin-top: 6.25rem"
                v-if="navIndex == 2"
              >
                <el-upload
                  class="avatar-uploader"
                  action="https://pt.baipubang.com/api/other/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  name="media"
                  :before-upload="beforeUpload"
                >
                  <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="店铺封面：" v-if="navIndex == 1 || navIndex == 3">
                <el-upload
                  class="avatar-uploader"
                  action="https://pt.baipubang.com/api/other/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  name="media"
                  :before-upload="beforeUpload"
                >
                  <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="店铺相册："
                v-if="navIndex == 1 || navIndex == 2 || navIndex == 3"
              >
                <el-upload
                  action="https://pt.baipubang.com/api/other/upload"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-success="handlePictureCardSuccess"
                  :before-upload="ImgbeforeUpload"
                  :file-list="form.imgList2"
                  :on-exceed="onexceeed"
                  name="media"
                  :limit="9"
                  multiple
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item
                label="视频："
                v-if="navIndex == 1 || navIndex == 2 || navIndex == 3"
              >
                <div v-if="form.videoUrl">
                  <div class="flex j-sb a-center">
                    <div>
                      <div class="fs-14 col-CC0000">
                        视频不超过30s，不可带其他平台水印视频
                      </div>
                      <div class="w528h400">
                        <video
                          width="100%"
                          height="100%"
                          :src="form.videoUrl"
                          controls
                        ></video>
                      </div>
                    </div>
                    <div>
                      <el-button type="danger" @click="deleVideo">删除</el-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flex">
                  <el-upload
                    class="avatar-uploader"
                    action="https://pt.baipubang.com/api/other/upload"
                    :show-file-list="false"
                    :on-success="handleVideoSuccess"
                    name="media"
                    :before-upload="handleBeforeUpload"
                  >
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <div class="fs-14 col-CC0000 ml15">
                    视频不超过30s，不可带其他平台水印视频
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="面积：" v-if="navIndex == 4">
                <div class="flex">
                  <el-input
                    v-model="form.area_min"
                    placeholder="请输入最小面积"
                  ></el-input>
                  ~
                  <el-input
                    v-model="form.area_max"
                    placeholder="请输入最大面积"
                  ></el-input>
                  <el-select v-model="form.area_unit" placeholder="请选择面积单位">
                    <el-option label="㎡" value="1"></el-option>
                    <el-option label="亩" value="2"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="面积：" v-if="navIndex == 1 || navIndex == 2">
                <div class="flex">
                  <el-input v-model="form.area" placeholder="请输入面积"></el-input>
                  <el-select v-model="form.area_unit" placeholder="请选择面积单位">
                    <el-option label="㎡" value="1"></el-option>
                    <el-option label="亩" value="2"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="租金：" v-if="navIndex == 4">
                <div class="flex">
                  <div class="flex" v-if="form.find_pricetype == 1">
                    <el-input
                      v-model="form.rent_price_min"
                      placeholder="请输入最低租金"
                    ></el-input>
                    ~
                    <el-input
                      v-model="form.rent_price_max"
                      placeholder="请输入最高租金"
                    ></el-input>
                    <el-select value="元" disabled placeholder="请选择价格单位">
                      <el-option label="元" value="元" disabled></el-option> </el-select
                    >/
                    <el-select v-model="form.rent_time" placeholder="请选择租期">
                      <el-option label="年" value="1"></el-option>
                      <el-option label="月" value="2"></el-option>
                      <el-option label="平方" value="3"></el-option>
                    </el-select>
                  </div>
                  <el-select v-model="form.find_pricetype" placeholder="请选择">
                    <el-option label="面议" value="0"></el-option>
                    <el-option label="租金" value="1"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="租金：" v-if="navIndex == 1">
                <div class="flex">
                  <el-input
                    v-model="form.rent_price"
                    placeholder="请输入,输入0为默认面议"
                  ></el-input>
                  <el-select v-model="form.money_unit" placeholder="请选择价格单位">
                    <el-option label="万元" value="万元"></el-option>
                    <el-option label="元" value="元"></el-option> </el-select
                  >/
                  <el-select v-model="form.rent_time" placeholder="请选择租期">
                    <el-option label="年" value="1"></el-option>
                    <el-option label="月" value="2"></el-option>
                    <el-option label="平方" value="3"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="转让费：" v-if="navIndex == 1 && form.type == 1">
                <div class="flex">
                  <el-input
                    v-model="form.transfer_fee"
                    v-if="form.transfer_price_list == 1"
                    placeholder="请输入转让费用"
                  ></el-input>
                  <el-select
                    v-model="form.transfer_price"
                    disabled
                    v-if="form.transfer_price_list == 1"
                  >
                    <el-option label="万元" value="万元"></el-option>
                  </el-select>
                  <el-select
                    v-model="form.transfer_price_list"
                    placeholder="请选择转让类型"
                  >
                    <el-option label="转让费" value="1"></el-option>
                    <el-option label="无转让费" value="2"></el-option>
                    <el-option label="面议" value="3"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="转让费：" v-if="navIndex == 4">
                <div class="flex">
                  <div class="flex" v-if="form.transfer_price_list == 1">
                    <el-input
                      v-model="form.transfer_fee_min"
                      placeholder="请输入最低转让费用"
                    ></el-input>
                    ~
                    <el-input
                      v-model="form.transfer_fee_max"
                      placeholder="请输入最高转让费用"
                    ></el-input>
                    <el-select v-model="form.transfer_price" disabled>
                      <el-option label="万元" value="万元"></el-option>
                    </el-select>
                  </div>
                  <el-select
                    v-model="form.transfer_price_list"
                    placeholder="请选择转让类型"
                  >
                    <el-option label="转让费" value="1"></el-option>
                    <el-option label="无转让费" value="2"></el-option>
                    <el-option label="面议" value="3"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="费用：" v-if="navIndex == 2 && form.type == 2">
                <div class="flex">
                  <el-input
                    v-model="form.transfer_fee"
                    v-if="form.transfer_price_list != 0"
                    placeholder="请输入费用"
                  ></el-input>
                  <el-select
                    v-model="form.transfer_price"
                    placeholder="请选择价格单位"
                    v-if="form.transfer_price_list != 0"
                  >
                    <el-option label="万元" value="万元"></el-option>
                    <el-option label="元" value="元"></el-option>
                  </el-select>
                  <el-select v-model="form.transfer_price_list" placeholder="请选择类型">
                    <el-option label="总价" value="1"></el-option>
                    <el-option label="平方" value="2"></el-option>
                    <el-option label="面议" value="0"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="是否空转：" v-if="navIndex == 1 && form.type == 1">
                <div class="flex">
                  <el-select v-model="form.is_null" placeholder="请选择是否空转">
                    <el-option label="可以" value="1"></el-option>
                    <el-option label="不可以" value="0"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item
                label="目前经营（可多选）："
                v-if="navIndex == 1 && form.type == 1"
              >
                <div class="flex">
                  <el-cascader
                    :options="Info.class_list"
                    :props="props"
                    v-model="form.class_two"
                    clearable
                    @change="classblur"
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item
                label="曾经经营（可多选）："
                v-if="navIndex == 1 && form.type == 2"
              >
                <div class="flex">
                  <el-cascader
                    :options="Info.class_list"
                    :props="props"
                    v-model="form.class_two"
                    clearable
                    @change="classblur"
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item label="准备经营：" v-if="navIndex == 4">
                <div class="flex">
                  <el-cascader
                    :options="Info.class_list"
                    :props="props"
                    v-model="form.class_two"
                    clearable
                    @change="classblur"
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item label="标签：" v-if="navIndex == 1 || navIndex == 2">
                <el-checkbox-group
                  v-model="form.label"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    :label="item.id"
                    name="form.label"
                    v-for="(item, index) in Info.label"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="剩余租期：" v-if="navIndex == 1 && form.type == 1">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="最短租期：" v-if="navIndex == 1 && form.type == 2">
                <el-checkbox-group
                  v-model="form.shortest"
                  @change="handleCheckedCitiesChange3"
                >
                  <el-checkbox :label="1" name="form.shortest">短租</el-checkbox>
                  <el-checkbox :label="2" name="form.shortest">长租</el-checkbox>
                  <el-checkbox :label="3" name="form.shortest">可分租</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="免租天数：" v-if="navIndex == 1 && form.type == 2">
                <el-input
                  v-model="form.expectdate"
                  placeholder="请输入免租天数"
                ></el-input>
                <el-select v-model="form.day" disabled placeholder="请选择">
                  <el-option label="天" value="天"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="期望租期：" v-if="navIndex == 4">
                <div class="flex a-center">
                  <el-input
                    v-model="form.expectdate"
                    placeholder="请输入期望租期"
                  ></el-input>
                  <el-select v-model="expectdatedate" disabled placeholder="请选择租期">
                    <el-option label="年" value="1"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="期望区域（可多选）：" v-if="navIndex == 4">
                <div class="flex">
                  <el-cascader
                    :options="Info.area_list"
                    :props="{ multiple: true, value: 'label', label: 'label' }"
                    clearable
                    v-model="form.cityList"
                    @change="classblur3"
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item label="店铺状态：" v-if="navIndex == 1 && form.type == 1">
                <el-select v-model="form.manage_state" placeholder="请选择活动区域">
                  <el-option label="营业中" value="1"></el-option>
                  <el-option label="停业" value="2"></el-option>
                  <el-option label="毛坯" value="3"></el-option>
                  <el-option label="空置" value="4"></el-option>
                  <el-option label="精装" value="5"></el-option>
                  <el-option label="设备齐全" value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="店铺状态：" v-if="navIndex == 1 && form.type == 2">
                <el-checkbox-group
                  v-model="form.manage_state2"
                  @change="handleCheckedCitiesChange4"
                >
                  <el-checkbox :label="1" name="form.manage_state2">营业中</el-checkbox>
                  <el-checkbox :label="2" name="form.manage_state2">停业</el-checkbox>
                  <el-checkbox :label="3" name="form.manage_state2">毛坯</el-checkbox>
                  <el-checkbox :label="4" name="form.manage_state2">空置</el-checkbox>
                  <el-checkbox :label="5" name="form.manage_state2">精装</el-checkbox>
                  <el-checkbox :label="6" name="form.manage_state2">设备齐全</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="适合行业（可多选）："
                v-if="navIndex == 1 || navIndex == 2"
              >
                <div class="flex">
                  <el-cascader
                    :options="Info.class_list"
                    :props="props"
                    clearable
                    v-model="form.class_two2"
                    @change="classblur2"
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item label="商圈/街道：" v-if="navIndex == 1">
                <el-input
                  v-model="form.cbd_name"
                  placeholder="请输入商圈/街道"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在楼层：" v-if="navIndex == 1 || navIndex == 2">
                <div class="flex a-center j-sb">
                  <div>
                    <el-select v-model="form.ds_floorid" placeholder="请选择">
                      <el-option label="单层" value="1"></el-option>
                      <el-option label="多层" value="2"></el-option>
                      <el-option label="独栋" value="3"></el-option>
                    </el-select>
                  </div>
                  <div class="flex a-center">
                    第
                    <el-input
                      v-model="form.ds_floornum"
                      placeholder="请输入所在楼层"
                    ></el-input>
                    层
                  </div>
                  <div class="flex a-center">
                    共
                    <el-input
                      v-model="form.ds_floorsum"
                      placeholder="请输入总共楼层"
                    ></el-input>
                    层
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="所属区域："
                v-if="navIndex == 1 || navIndex == 2 || navIndex == 3"
              >
                <el-cascader
                  v-model="form.citytext"
                  :options="cityList"
                  ref="locationCascader"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'label',
                  }"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="详细地址："
                v-if="navIndex == 1 || navIndex == 2 || navIndex == 3"
              >
                <div class="city_d">
                  <el-input
                    v-model="form.address"
                    placeholder="请输入详细地址"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="公司名称：" v-if="navIndex == 3 && form.type == 2">
                <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
              </el-form-item>
              <el-form-item label="店铺介绍：" v-if="navIndex == 1">
                <Editor v-model="form.content" />
              </el-form-item>
              <el-form-item label="商品描述：" v-if="navIndex == 3 && form.type == 2">
                <Editor v-model="form.content" />
              </el-form-item>
              <el-form-item
                label="配套设施："
                style="margin-top: 6.25rem"
                v-if="navIndex == 1"
              >
                <el-checkbox-group
                  v-model="form.matching"
                  @change="handleCheckedCitiesChange2"
                >
                  <el-checkbox
                    :label="item.id"
                    name="form.label"
                    v-for="(item, index) in Info.matching"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="配套设施：" v-if="navIndex != 1 && navIndex != 3">
                <el-checkbox-group
                  v-model="form.matching"
                  @change="handleCheckedCitiesChange2"
                >
                  <el-checkbox
                    :label="item.id"
                    name="form.label"
                    v-for="(item, index) in Info.matching"
                    :key="index"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="联系人："
                style="margin-top: 6.25rem"
                v-if="navIndex == 3 && form.type == 2"
              >
                <el-input v-model="form.linkName" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系人：" v-else>
                <el-input v-model="form.linkName" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系电话：">
                <el-input
                  v-model="form.linkPhone"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="标题：">
                <div class="flex a-center city_d">
                  <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                  <el-button
                    type="primary"
                    size="small"
                    style="margin-left: 1.25rem"
                    @click="createtag"
                    >自动生成可修改</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="sbmitInfo()"
                  >发布信息</el-button
                >
              </el-form-item>
            </el-form>
            <div class="mt30" v-if="navIndex == 3 && form.type == 1">
              <div class="flex a-center mb30">
                <div class="fs-24 col-333333 fwb mr15">物品清单</div>
                <div>
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    circle
                    @click="addinventory"
                  ></el-button>
                </div>
              </div>
              <div>
                <el-table :data="inventory" border height="250" style="width: 100%">
                  <el-table-column label="品类">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.category"
                        placeholder="请输入品类"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="成色">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.condition"
                        placeholder="请输入成色"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="数量">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.number"
                        placeholder="请输入数量"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="价格">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.price"
                        placeholder="请输入价格"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  send_view,
  city_area_list,
  instructions,
  salesman_list,
  edit_drafts_sub,
  edit_drafts_view,
} from "../../utils/api.js";
import Editor from "../../components/quillEditor.vue";
// import { json } from "body-parser";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      expectdatedate:'1',
      modalKey: 0,
      inventory: [],
      props: { multiple: true, value: "id", label: "name", children: "child" },
      select_userid: "", //选择的业务员
      gridData: "", //业务员列表
      dialogTableVisible: false, //控制业务员弹框
      centerDialogVisible: false, //控制协议弹框
      content: "", //协议内容
      dialogImageUrl: "", //放大相册的图片
      dialogVisible: false, //控制放大相册的图片
      navIndex: "1", //发布类型
      form: {
        day: "天",
        content: "", //富文本内容
        cityList: [], //期望区域
        expectdate: "", //期望租期/免租天数
        find_pricetype: "0", //找店租金类型
        manage_state2: [], //店铺状态-出租
        shortest: [], //最短租期
        linkName: "", //联系人
        linkPhone: "", //联系电话
        title: "", //标题
        matching: [], //配套设施
        province: "", //省
        city: "", //市
        city_area: "", //区
        citytext: [], //区域总和
        address: "", //详细地址
        manage_state: "", //店铺状态-转让
        label: [], //标签
        type: "1", //类型
        cover: "", //封面-相对链接
        imgUrl: "", //封面-绝对链接
        imgList2: [], //店铺相册
        video: "", //视频-相对链接
        videoUrl: "", //视频-绝对链接
        area: "", //面积-转让出租/招商出售
        area_unit: "1", //面积单位
        rent_price: "", //租金-转让出租/招商出售
        money_unit: "万元", //租金价格单位
        rent_time: "1", //租金租期
        transfer_fee: "", //转让费/费用
        transfer_price: "万元", //转让费价格单位
        transfer_price_list: "1", //转让费类型
        class_two: "", //准备经营/曾经经营/目前经营
        class_two2: "", //适合行业
        is_null: "", //是否空转
        cbd_name: "", //商圈/街道/商圈名称
        rent_price_min: "", //最低租金
        rent_price_max: "", //最高租金
        transfer_fee_min: "", //最低转让费
        transfer_fee_max: "", //最高转让费
        area_min: "", //最小面积
        area_max: "", //最大面积
        date: "", //剩余租期
        ds_floorid: "", //单双层
        ds_floornum: "", //所在楼层
        ds_floorsum: "", //总共楼层
        company: "", //公司名称
      },
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
      },
      cityList: [], //获取的城市
      lng: "", //纬度
      lat: "", //经度
      province: "", //省
      city: "", //市
      Info: "", //获取的数据
      uid: "",
      s_type: "",
      id: "",
      status: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.s_type = this.$route.query.s_type;
        this.id = this.$route.query.id;
        if (this.s_type && this.id) {
          if (this.s_type == 1) {
            this.navIndex = 1;
          } else if (this.s_type == 2) {
            this.navIndex = 2;
          } else if (this.s_type == 4) {
            this.navIndex = 4;
          } else {
            this.navIndex = 3;
          }
          setTimeout(() => {
            this.getdata();
          }, 800);
        }
      },
    },
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: { navid: 10 },
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
    this.s_type = this.$route.query.s_type;
    this.id = this.$route.query.id;
    this.lng = window.localStorage.getItem("lng");
    this.lat = window.localStorage.getItem("lat");
    this.province = window.localStorage.getItem("province");
    this.city = window.localStorage.getItem("city");
    this.Releasenotes();
    this.getbusinessManager();
    this.getInfo();
    this.getcity();
    if (this.s_type && this.id) {
      if (this.s_type == 1) {
        this.navIndex = 1;
      } else if (this.s_type == 2) {
        this.navIndex = 2;
      } else if (this.s_type == 4) {
        this.navIndex = 4;
      } else {
        this.navIndex = 3;
      }
      setTimeout(() => {
        this.getdata();
      }, 800);
    }
  },
  methods: {
    getdata() {
      edit_drafts_view({
        id: this.id,
        type: this.s_type,
        uid: this.uid,
      })
        .then((res) => {
          console.log(res);
          let val = res.data.val;
          this.status = val.status;
          if(this.s_type==3||this.s_type==5){
            if(val.type==5){
              this.form.type = '2';
            }else{
              this.form.type = '1';
            }
          }else{
            this.form.type = String(val.type);

          }
          this.form.cover = val.cover;
          this.form.imgUrl = val.path_cover;
          this.form.video = val.video;
          this.form.videoUrl = val.path_video;
          this.form.area = val.area;
          this.form.area_unit = String(val.area_unit);
          this.form.rent_price = val.rent_price;
          this.form.money_unit = val.money_unit;
      
          this.form.date = val.contract_time;
          if(val.floor_arr&&val.floor_arr.length){
            this.form.ds_floorid = val.floor_arr[0];
            this.form.ds_floornum = val.floor_arr[1];
            this.form.ds_floorsum = val.floor_arr[2];
          }
          let citytext = [];
          citytext.push(val.province);
          citytext.push(val.city);
          citytext.push(val.city_area);
          this.form.citytext = JSON.parse(JSON.stringify(citytext));
          this.form.province = val.province;
          this.form.city = val.city;
          this.form.city_area = val.city_area;
          this.form.address = val.address;
          this.form.content = val.introduce;
          this.form.linkPhone = val.phone;
          this.form.title = val.title;
          
         
          if (val.rental && val.rental.length) {
            let shortest = [];
            val.rental.forEach((item) => {
              shortest.push(Number(item.id));
            });
            this.form.shortest = shortest;
          }
          if (val.matching && val.matching.length) {
            let matching = [];
            val.matching.forEach((item) => {
              matching.push(Number(item.id));
            });
            this.form.matching = matching;
          }
          if (val.open_status && val.open_status.length) {
            if (val.type == 1) {
              this.form.manage_state = String(val.open_status[0].id);
            } else {
              let manage_state2 = [];
              val.open_status.forEach((item) => {
                manage_state2.push(Number(item.id));
              });
              this.form.manage_state2 = manage_state2;
            }
          }
         
          this.form.is_null = String(val.is_null_poss);
          if (val.classify_two && val.classify_two.length) {
            let class_two = [];
            val.classify_two.forEach((item) => {
              class_two.push([Number(item.fid), Number(item.id)]);
            });
            this.form.class_two = class_two;
          }
          if (val.fit_industry && val.fit_industry.length) {
            let class_two2 = [];
            val.fit_industry.forEach((item) => {
              class_two2.push([Number(item.fid), Number(item.id)]);
            });
            this.form.class_two2 = class_two2;
          }
          if (val.val_label && val.val_label.length) {
            let label = [];
            val.val_label.forEach((item) => {
              label.push(Number(item.id));
            });
            this.form.label = label;
          }
          if (val.images && val.images.length) {
            let arr = [];
            val.images.forEach((item) => {
              let obj = {
                name: item.id,
                url: item.path_img,
                path: item.img,
              };
              arr.push(JSON.parse(JSON.stringify(obj)));
            });
            this.form.imgList2 = arr;
          }
          console.log(this.s_type);
          if(this.s_type==1){
            this.form.transfer_fee = val.sell_price;
            this.form.linkName = val.contacts;
            this.form.expectdate = val.free_day;
            this.form.rent_time = String(val.rent_time);
            this.form.cbd_name = val.street
            if (val.sell_price < 0) {
            this.form.transfer_price_list = "2";
          } else if (val.sell_price == 0) {
            this.form.transfer_price_list = "3";
          } else {
            this.form.transfer_price_list = "1";
          }
          }else if(this.s_type==2){
            this.form.transfer_price_list = val.rent_type
            this.form.transfer_price = val.money_unit
            this.form.transfer_fee = val.sell_money;
            this.form.linkName = val.contacts;

            this.form.rent_time = String(val.rent_time);
            this.form.cbd_name = val.name;
          }else if(this.s_type==3){
            this.form.linkName = val.contacts;
            if(val.old_goods_list&&val.old_goods_list.length){
              this.inventory = val.old_goods_list
            }
              console.log("8888");
          }else if(this.s_type==4){

            this.form.linkName = val.name
            this.form.expectdate = val.expect_lease_term
            this.form.find_pricetype= String(val.rent_price_list);
            this.form.rent_time = String(val.rent_type);
            this.form.area_min = val.area_min
            this.form.area_max = val.area_max
            this.form.rent_price_min = val.rent_min
            this.form.rent_price_max = val.rent_max
            this.form.transfer_fee_min = val.transfer_price_min
            this.form.transfer_fee_max = val.transfer_price_max
            this.form.transfer_price_list =
            String(val.transfer_price_list)
            if(val.expect_area&&val.expect_area.length){
              let cityList = []
              val.expect_area.forEach(item=>{
                cityList.push([item.pname,item.fname,item.name])
              })
              this.form.cityList = cityList
            }
          }else if(this.s_type==5){
            this.form.linkName = val.contacts;
            console.log("111");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(index, row) {
      this.inventory.splice(index, 1);
      console.log(index, row);
      console.log(this.inventory);
    },
    addinventory() {
      let inventory = this.inventory;
      if (inventory && inventory.length) {
        let flag = true;
        inventory.forEach((item) => {
          if (!item.category) {
            this.$message.error("还有未填品类");
            flag = false;
          } else if (!item.condition) {
            this.$message.error("还有未填成色");
            flag = false;
          } else if (!item.number) {
            this.$message.error("还有未填数量");
            flag = false;
          } else if (!item.price) {
            this.$message.error("还有未填价格");
            flag = false;
          }
        });
        if (flag) {
          inventory.push({
            category: "",
            number: "",
            condition: "",
            price: "",
          });
        }
      } else {
        inventory.push({
          category: "",
          number: "",
          condition: "",
          price: "",
        });
      }
      this.inventory = inventory;
      console.log("inventory", this.inventory);
    },
    WinportClick(id) {
      this.navIndex = id;
      this.form = {
        day: "天",
        content: "", //富文本内容
        cityList: [], //期望区域
        expectdate: "", //期望租期/免租天数
        find_pricetype: "0", //找店租金类型
        manage_state2: [], //店铺状态-出租
        shortest: [], //最短租期
        linkName: "", //联系人
        linkPhone: "", //联系电话
        title: "", //标题
        matching: [], //配套设施
        province: "", //省
        city: "", //市
        city_area: "", //区
        citytext: "", //区域总和
        address: "", //详细地址
        manage_state: "", //店铺状态-转让
        label: [], //标签
        type: "1", //类型
        cover: "", //封面-相对链接
        imgUrl: "", //封面-绝对链接
        imgList2: [], //店铺相册
        video: "", //视频-相对链接
        videoUrl: "", //视频-绝对链接
        area: "", //面积-转让出租/招商出售
        area_unit: "1", //面积单位
        rent_price: "", //租金-转让出租/招商出售
        money_unit: "万元", //租金价格单位
        rent_time: "1", //租金租期
        transfer_fee: "", //转让费/费用
        transfer_price: "万元", //转让费价格单位
        transfer_price_list: "1", //转让费类型
        class_two: "", //准备经营/曾经经营/目前经营
        class_two2: "", //适合行业
        is_null: "", //是否空转
        cbd_name: "", //商圈/街道/商圈名称
        rent_price_min: "", //最低租金
        rent_price_max: "", //最高租金
        transfer_fee_min: "", //最低转让费
        transfer_fee_max: "", //最高转让费
        area_min: "", //最小面积
        area_max: "", //最大面积
        date: "", //剩余租期
        ds_floorid: "", //单双层
        ds_floornum: "", //所在楼层
        ds_floorsum: "", //总共楼层
        company: "", //公司名称
      };
      this.inventory = [];
      this.getInfo();
    },
    sbmitInfo() {
      var that = this;
      let classify_two = "";
      if (that.form.class_two.length && that.form.class_two) {
        let arr = [];
        that.form.class_two.forEach((item) => {
          arr.push(item[1]);
        });
        classify_two = arr.join(",");
      }
      let label = "";
      if (that.form.label.length && that.form.label) {
        label = that.form.label.join(",");
      }
      let open_status = "";
      if (that.form.type == 1) {
        open_status = that.form.manage_state;
      } else {
        if (that.form.manage_state2.length && that.form.manage_state2) {
          open_status = that.form.manage_state2.join(",");
        }
      }
      let fit_industry = "";
      if (that.form.class_two2.length && that.form.class_two2) {
        let arr = [];
        that.form.class_two2.forEach((item) => {
          arr.push(item[1]);
        });
        fit_industry = arr.join(",");
      }
      let matching = "";
      if (that.form.matching.length && that.form.matching) {
        matching = that.form.matching.join(",");
      }
      let rental = "";
      if (that.form.shortest.length && that.form.shortest) {
        rental = that.form.shortest.join(",");
      }
      console.log(that.form.imgList2);
      let img = "";
      if (that.form.imgList2.length && that.form.imgList2) {
        let arr = [];
        that.form.imgList2.forEach((item) => {
          if (item.response) {
            arr.push(item.response.data.media);
          } else {
            arr.push(item.path);
          }
        });
        img = arr.join(",");
      }
      let add = "";
      if (that.form.cityList.length && that.form.cityList) {
        let arr = [];
        that.form.cityList.forEach((item) => {
          arr.push(item[2]);
        });
        add = arr.join(",");
      }
      if (that.navIndex == 1) {
        let sell_price = "";
        if (that.form.transfer_fee < 0) {
          sell_price = "-1";
        } else if (that.form.transfer_fee == 0) {
          sell_price == 0;
        } else {
          sell_price = that.form.transfer_fee;
        }
        edit_drafts_sub({
          id: that.id,
          edit_type: that.s_type,
          status: that.status,
          // code: that.Info.code,
          uid: that.uid,
          type: that.form.type,
          video: that.form.video,
          img: img,
          area: that.form.area,
          area_unit: that.form.area_unit,
          rent_price: that.form.rent_price,
          rent_time: that.form.rent_time,
          classify_two: classify_two,
          label: label,
          contract_time: that.form.date,
          free_day: that.form.expectdate,
          open_status: open_status,
          fit_industry: fit_industry,
          street: that.form.cbd_name,
          province: that.form.province,
          city: that.form.city,
          city_area: that.form.city_area,
          address: that.form.address,
          introduce: that.form.content,
          matching: matching,
          contacts: that.form.linkName,
          phone: that.form.linkPhone,
          title: that.form.title,
          sell_price: sell_price,
          is_null_poss: that.form.is_null,
          content: "",
          remarks: "",
          rental: rental,
          floor:
            that.form.ds_floorid +
            "|" +
            that.form.ds_floornum +
            "|" +
            that.form.ds_floorsum,
          cover: that.form.cover,
          salesman: that.select_userid,
          money_unit: that.form.money_unit,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            // this.$message.success(res.msg);
            // setTimeout(() => {
            this.$router.push({
              path: "/member",
              query: { navid: 10 },
            });
            // let routeData = this.$router.resolve({
            //   path: "/payment",
            //   query: { navid: 10, s_type: res.data.type, id: res.data.id },
            // });
            // window.open(routeData.href, "_blank");
            // }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      } else if (that.navIndex == 2) {
        edit_drafts_sub({
          id: that.id,
          edit_type: that.s_type,
          status: that.status,
          // code: that.Info.code,
          uid: that.uid,
          type: that.form.type,
          video: that.form.video,
          img: img,
          area: that.form.area,
          area_unit: that.form.area_unit,
          label: label,
          fit_industry: fit_industry,
          name: that.form.cbd_name,
          province: that.form.province,
          city: that.form.city,
          city_area: that.form.city_area,
          address: that.form.address,
          introduce: that.form.content,
          rent_type: that.form.transfer_price_list,
          matching: matching,
          contacts: that.form.linkName,
          phone: that.form.linkPhone,
          title: that.form.title,
          sell_money: that.form.transfer_fee,
          content: "",
          remarks: "",
          floor:
            that.form.ds_floorid +
            "|" +
            that.form.ds_floornum +
            "|" +
            that.form.ds_floorsum,
          cover: that.form.cover,
          salesman: that.select_userid,
          money_unit: that.form.money_unit,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            // this.$message.success(res.msg);
            // setTimeout(() => {
            this.$router.push({
              path: "/member",
              query: { navid: 10 },
            });
            // let routeData = this.$router.resolve({
            //   path: "/payment",
            //   query: { navid: 10, s_type: res.data.type, id: res.data.id },
            // });
            // window.open(routeData.href, "_blank");
            // }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      } else if (that.navIndex == 3) {
        if (that.form.type == 1) {
          let category = "";
          let condition = "";
          let number = "";
          let price = "";
          if (!this.inventory.length) {
            this.$message.error("请添加二手物品");
            return;
          }
          let flag = true;
          this.inventory.forEach((item) => {
            if (!item.category) {
              this.$message.error("还有未填品类");
              flag = false;
            } else if (!item.condition) {
              this.$message.error("还有未填成色");
              flag = false;
            } else if (!item.number) {
              this.$message.error("还有未填数量");
              flag = false;
            } else if (!item.price) {
              this.$message.error("还有未填价格");
              flag = false;
            }
          });
          if (flag) {
            category = this.inventory.map((e) => e.category).join(",");
            condition = this.inventory.map((e) => e.condition).join(",");
            number = this.inventory.map((e) => e.number).join(",");
            price = this.inventory.map((e) => e.price).join(",");
          }
          edit_drafts_sub({
            id: that.id,
            edit_type: that.s_type,
            status: that.status,
            // code: that.Info.code,
            uid: that.uid,
            video: that.form.video,
            img: img,
            province: that.form.province,
            city: that.form.city,
            city_area: that.form.city_area,
            address: that.form.address,
            contacts: that.form.linkName,
            phone: that.form.linkPhone,
            title: that.form.title,
            content: "",
            cover: that.form.cover,
            salesman: that.select_userid,
            // 品类
            category: category,
            // 成色
            condition: condition,
            // 数量
            number: number,
            // 价格
            price: price,
          })
            .then((res) => {
              console.log(res);
              if (res.code != 200) {
                this.$message.error(res.msg);
                return;
              }
              this.$message.success(res.msg);
              // setTimeout(() => {
              this.$router.push({
                path: "/member",
                query: { navid: 10 },
              });
              // let routeData = this.$router.resolve({
              //   path: "/member",
              //   query: { navid: 10 },
              // });
              // window.open(routeData.href, "_blank");
              // }, 1500);
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
            });
        } else {
          edit_drafts_sub({
            id: that.id,
            edit_type: that.s_type,
            status: that.status,
            // code: that.Info.code,
            uid: that.uid,
            video: that.form.video,
            img: img,
            province: that.form.province,
            city: that.form.city,
            city_area: that.form.city_area,
            address: that.form.address,
            contacts: that.form.linkName,
            phone: that.form.linkPhone,
            title: that.form.title,
            content: that.form.content,
            remarks: "",
            name: that.form.company,
            cover: that.form.cover,
            salesman: that.select_userid,
          })
            .then((res) => {
              console.log(res);
              if (res.code != 200) {
                this.$message.error(res.msg);
                return;
              }
              // this.$message.success(res.msg);
              // setTimeout(() => {
              this.$router.push({
                path: "/member",
                query: { navid: 10},
              });
              // let routeData = this.$router.resolve({
              //   path: "/payment",
              //   query: { navid: 10, s_type: res.data.type, id: res.data.id },
              // });
              // window.open(routeData.href, "_blank");
              // }, 1500);
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
            });
        }
      } else if (that.navIndex == 4) {
        edit_drafts_sub({
          id: that.id,
          edit_type: that.s_type,
          uid: that.uid,
          type: 4,
          status: that.status,
          // code: that.Info.code,
          area_min: that.form.area_min,
          area_max: that.form.area_max,
          area_unit: that.form.area_unit,
          rent_min: that.form.rent_price_min,
          rent_max: that.form.rent_price_max,
          rent_type: that.form.rent_time,
          transfer_price_min: that.form.transfer_fee_min,
          transfer_price_max: that.form.transfer_fee_max,
          classify_two: classify_two,
          expect_lease_term: that.form.expectdate,
          expect_area: add,
          matching: matching,
          name: that.form.linkName,
          phone: that.form.linkPhone,
          title: that.form.title,
          transfer_price_list: that.form.transfer_price_list,
          salesman: that.select_userid,
          rent_price_list: that.form.find_pricetype,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success(res.msg);
            setTimeout(() => {
              this.$router.push({
                path: "/member",
                query: { navid: 10 },
              });
              // let routeData = this.$router.resolve({
              //   path: "/member",
              //   query: { navid: 10 },
              // });
              // window.open(routeData.href, "_blank");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      }
    },
    createtag() {
      var that = this;
      let area_unit = "";
      if (that.form.area_unit == 1) {
        area_unit = "㎡";
      } else {
        area_unit = "亩";
      }
      let class_two = "";
      if (
        that.Info.class_list &&
        that.Info.class_list.length &&
        that.form.class_two.length
      ) {
        that.Info.class_list.forEach((item) => {
          if (item.id == that.form.class_two[0][0]) {
            item.child.forEach((items) => {
              if (items.id == that.form.class_two[0][1]) {
                class_two = items.name;
              }
            });
          }
        });
      }
      if (that.navIndex == 1) {
        if (that.form.type == 1) {
          // 区域+商圈/街道+面积+行业+经营状态+转让
          let manage_state = "";
          if (that.form.manage_state == 1) {
            manage_state = "营业中";
          } else if (that.form.manage_state == 2) {
            manage_state = "停业";
          } else if (that.form.manage_state == 3) {
            manage_state = "毛坯";
          } else if (that.form.manage_state == 4) {
            manage_state = "空置";
          } else if (that.form.manage_state == 5) {
            manage_state = "精装";
          } else if (that.form.manage_state == 6) {
            manage_state = "设备齐全";
          }
          that.form.title =
            that.form.city_area +
            that.form.cbd_name +
            that.form.area +
            area_unit +
            class_two +
            manage_state +
            "转让";
        } else {
          console.log(that.form.manage_state2);
          let manage_state2 = "";
          if (that.form.manage_state2[0] == 1) {
            manage_state2 = "营业中";
          } else if (that.form.manage_state2[0] == 2) {
            manage_state2 = "停业";
          } else if (that.form.manage_state2[0] == 3) {
            manage_state2 = "毛坯";
          } else if (that.form.manage_state2[0] == 4) {
            manage_state2 = "空置";
          } else if (that.form.manage_state2[0] == 5) {
            manage_state2 = "精装";
          } else if (that.form.manage_state2[0] == 6) {
            manage_state2 = "设备齐全";
          }
          // 区域+商圈/街道+面积+行业+经营状态+出租
          that.form.title =
            that.form.city_area +
            that.form.cbd_name +
            that.form.area +
            area_unit +
            class_two +
            manage_state2 +
            "出租";
        }
      } else if (that.navIndex == 2) {
        if (that.form.type == 1) {
          // 区域+商圈+面积+招商
          that.form.title =
            that.form.city_area +
            that.form.cbd_name +
            that.form.area +
            area_unit +
            "招商";
        } else {
          // 区域+商圈+面积+出售
          that.form.title =
            that.form.city_area +
            that.form.cbd_name +
            that.form.area +
            area_unit +
            "出售";
        }
      } else if (that.navIndex == 4) {
        // 区域+面积+行业+经营类型+找店
        that.form.title =
          that.form.cityList[0][2] + that.form.area_min + area_unit + class_two + "找店";
      } else if (that.navIndex == 3) {
        if (that.form.type == 1) {
          that.form.title = that.form.city_area + "二手出售";
        } else {
          that.form.title = that.form.city_area + "二手收购";
        }
      }
    },
    handleChange(value) {
      this.form.province = value[0];
      this.form.city = value[1];
      this.form.city_area = value[2];
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      this.form.label = value;
      console.log(this.form.label);
    },
    handleCheckedCitiesChange2(value) {
      console.log(value);
      this.form.matching = value;
      console.log(this.form.matching);
    },
    handleCheckedCitiesChange3(value) {
      console.log(value);
      this.form.shortest = value;
      console.log(this.form.shortest);
    },
    handleCheckedCitiesChange4(value) {
      console.log(value);
      this.form.manage_state2 = value;
      console.log(this.form.manage_state2);
    },
    classblur(e) {
      // console.log('classblur',e);
      // let arr = [];
      // e.forEach((item) => {
      //   arr.push(item[1]);
      // });
      // this.form.class_two = arr;
      console.log(this.form.class_two, e);
    },
    classblur2(e) {
      // console.log('classblur',e);
      // let arr = [];
      // e.forEach((item) => {
      //   arr.push(item[1]);
      // });
      // this.form.class_two2 = arr;
      console.log(this.form.class_two2, e);
    },
    classblur3(e) {
      console.log("classblur3", e);
      let arr = [];
      if (e.length && e) {
        e.forEach((item) => {
          arr.push(item[2]);
        });
        this.Info.area_list.forEach((item) => {
          item.children.forEach((items) => {
            if (items.label == e[0][1]) {
              items.disabled = false;
            } else {
              items.disabled = true;
            }
          });
        });
      } else {
        this.Info.area_list.forEach((item) => {
          item.children.forEach((items) => {
            items.disabled = false;
          });
        });
      }
      // this.form.cityList = arr;
      console.log(this.form.cityList);
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.$message({
        message: "您已选择" + row.name + "为您服务",
        type: "success",
      });
      this.select_userid = row.id;
      this.dialogTableVisible = false;
    },
    consent() {
      this.centerDialogVisible = false;
      this.dialogTableVisible = true;
    },
    // 封面
    beforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.cover = res.data.media;
      this.form.imgUrl = res.data.path_media;
    },
    // 视频
    deleVideo() {
      this.form.video = "";
      this.form.videoUrl = "";
    },
    // 获取视频时长
    getVideoDuration(file) {
      return new Promise(function (resolve, reject) {
        //做一些异步操作
        let url = URL.createObjectURL(file);
        let audioElement = new Audio(url);
        let duration = 0;
        audioElement.addEventListener("loadedmetadata", function () {
          duration = audioElement.duration; //时长为秒，小数，182.36
          resolve(duration);
        });
        console.log(reject);
      });
    },
    handleBeforeUpload(file) {
      console.log("file", file);
      return new Promise((resolve, reject) => {
        if (file.type !== "video/mp4") {
          this.$message.warning("仅支持mp4格式");
          return reject();
        }
        this.getVideoDuration(file).then((duration) => {
          if (duration > 30) {
            this.$message.warning("请上传时长小于30s的视频");
            return reject();
          } else {
            resolve();
          }
        });
      });
    },

    VideobeforeUpload(file) {
      console.log("file", file);
      let that = this;
      const ismp4 = file.type === "video/mp4";
      var url = URL.createObjectURL(file);
      var audioElement = new Audio(url);
      var duration;
      if (!ismp4) {
        that.$message.error("只能上传视频文件!");
      }
      audioElement.addEventListener("loadedmetadata", function () {
        duration = parseInt(audioElement.duration); //时长为秒，取整
      });
      console.log("秒数取整", duration);
      const ismp5 = duration <= 30;
      if (!ismp5) {
        that.$message.error("上传视频文件不能超过30s!");
      }
      console.log(ismp5);
      return ismp4 && ismp5;
    },
    handleVideoSuccess(res, file) {
      console.log(res, file);
      this.form.video = res.data.media;
      this.form.videoUrl = res.data.path_media;
    },
    // 相册
    ImgbeforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";
      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.form.imgList2 = fileList;
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      this.form.imgList2 = fileList;
    },
    // file 是当前上传的文件，fileList是上传过的文件
    onexceeed(file, fileList) {
      // 对超出limit限制自定义处理提示
      console.log(fileList);
      console.log(file);
      if (fileList.length + file.length > 9) {
        // 提示超出
        this.$message.error("至多只能上传9张图片！");
      }
    },
    // 获取发布信息
    getInfo() {
      let that = this;
      send_view({
        area: "",
        city: that.city,
        province: that.province,
        type: that.navIndex,
        uid:that.uid
      })
        .then((res) => {
          console.log(res);
          that.Info = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取城市
    getcity() {
      let that = this;
      city_area_list({})
        .then((res) => {
          console.log(res);
          that.cityList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getbusinessManager() {
      let that = this;
      salesman_list({
        city: that.city,
      })
        .then((res) => {
          console.log(res);
          that.gridData = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取发布须知
    Releasenotes() {
      let that = this;
      instructions({})
        .then((res) => {
          console.log(res);
          that.content = res.data
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeType(e) {
      console.log(e);
      console.log(this.form.type);
      this.form = {
        type: e,
        day: "天",
        content: "", //富文本内容
        cityList: [], //期望区域
        expectdate: "", //期望租期/免租天数
        find_pricetype: "0", //找店租金类型
        manage_state2: [], //店铺状态-出租
        shortest: [], //最短租期
        linkName: "", //联系人
        linkPhone: "", //联系电话
        title: "", //标题
        matching: [], //配套设施
        province: "", //省
        city: "", //市
        city_area: "", //区
        citytext: "", //区域总和
        address: "", //详细地址
        manage_state: "", //店铺状态-转让
        label: [], //标签
        cover: "", //封面-相对链接
        imgUrl: "", //封面-绝对链接
        imgList2: [], //店铺相册
        video: "", //视频-相对链接
        videoUrl: "", //视频-绝对链接
        area: "", //面积-转让出租/招商出售
        area_unit: "1", //面积单位
        rent_price: "", //租金-转让出租/招商出售
        money_unit: "万元", //租金价格单位
        rent_time: "1", //租金租期
        transfer_fee: "", //转让费/费用
        transfer_price: "万元", //转让费价格单位
        transfer_price_list: "1", //转让费类型
        class_two: "", //准备经营/曾经经营/目前经营
        class_two2: "", //适合行业
        is_null: "", //是否空转
        cbd_name: "", //商圈/街道/商圈名称
        rent_price_min: "", //最低租金
        rent_price_max: "", //最高租金
        transfer_fee_min: "", //最低转让费
        transfer_fee_max: "", //最高转让费
        area_min: "", //最小面积
        area_max: "", //最大面积
        date: "", //剩余租期
        ds_floorid: "", //单双层
        ds_floornum: "", //所在楼层
        ds_floorsum: "", //总共楼层
        company: "", //公司名称
      };
      this.inventory = [];
      this.getInfo();
    },
    select(e) {
      this.navIndex = e;
      this.form = {
        day: "天",
        content: "", //富文本内容
        cityList: [], //期望区域
        expectdate: "", //期望租期/免租天数
        find_pricetype: "0", //找店租金类型
        manage_state2: [], //店铺状态-出租
        shortest: [], //最短租期
        linkName: "", //联系人
        linkPhone: "", //联系电话
        title: "", //标题
        matching: [], //配套设施
        province: "", //省
        city: "", //市
        city_area: "", //区
        citytext: "", //区域总和
        address: "", //详细地址
        manage_state: "", //店铺状态-转让
        label: [], //标签
        type: "1", //类型
        cover: "", //封面-相对链接
        imgUrl: "", //封面-绝对链接
        imgList2: [], //店铺相册
        video: "", //视频-相对链接
        videoUrl: "", //视频-绝对链接
        area: "", //面积-转让出租/招商出售
        area_unit: "1", //面积单位
        rent_price: "", //租金-转让出租/招商出售
        money_unit: "万元", //租金价格单位
        rent_time: "1", //租金租期
        transfer_fee: "", //转让费/费用
        transfer_price: "万元", //转让费价格单位
        transfer_price_list: "1", //转让费类型
        class_two: "", //准备经营/曾经经营/目前经营
        class_two2: "", //适合行业
        is_null: "", //是否空转
        cbd_name: "", //商圈/街道/商圈名称
        rent_price_min: "", //最低租金
        rent_price_max: "", //最高租金
        transfer_fee_min: "", //最低转让费
        transfer_fee_max: "", //最高转让费
        area_min: "", //最小面积
        area_max: "", //最大面积
        date: "", //剩余租期
        ds_floorid: "", //单双层
        ds_floornum: "", //所在楼层
        ds_floorsum: "", //总共楼层
        company: "", //公司名称
      };
      this.inventory = [];
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }
.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}
.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
.release {
  .el-input {
    width: auto !important;
  }
  .city_d {
    .el-input {
      width: 100% !important;
    }
  }
  .el-upload--picture-card {
    width: 11.125rem !important;
    height: 11.125rem !important;
    line-height: 11.125rem !important;
    background-color: #ffffff !important;
    border: 1px dashed #d2d2d2 !important;
  }
  .editor {
    height: 25rem !important;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 11.125rem !important;
    height: 11.125rem !important;
  }
}
</style>
